<template>
  <div class="animated fadeIn">
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1>{{ $t('message.faq') }}</h1>
          </div>
          <div role="tablist">
            <b-card class="mb-1 p-2" no-body>
              <b-card-header class="p-1" header-tag="header" role="tab">
                <b-button block href="#" v-b-toggle.accordion-1 class="bg-theme-secondary">Accordion 1</b-button>
              </b-card-header>
              <b-collapse accordion="my-accordion" id="accordion-1" role="tabpanel" visible>
                <b-card-body>
                  <b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text>
                  <b-card-text>{{ text }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card class="mb-1 p-2" no-body>
              <b-card-header class="p-1" header-tag="header" role="tab">
                <b-button block href="#" v-b-toggle.accordion-2 class="bg-theme-secondary">Accordion 2</b-button>
              </b-card-header>
              <b-collapse accordion="my-accordion" id="accordion-2" role="tabpanel">
                <b-card-body>
                  <b-card-text>{{ text }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card class="mb-1 p-2" no-body>
              <b-card-header class="p-1" header-tag="header" role="tab">
                <b-button block href="#" v-b-toggle.accordion-3 class="bg-theme-secondary">Accordion 3</b-button>
              </b-card-header>
              <b-collapse accordion="my-accordion" id="accordion-3" role="tabpanel">
                <b-card-body>
                  <b-card-text>{{ text }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Faq",
    data() {
      return {
        text: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry\n' +
          '          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor\n' +
          '          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon\n' +
          '          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla\n' +
          '          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore\n' +
          '          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher\n' +
          '          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic\n' +
          '          synth nesciunt you probably haven\'t heard of them accusamus labore VHS.'
      }
    }

  }
</script>

<style scoped>

</style>
